<template>
  <AppRow permission="CHAT_ADMINISTRATION">
    <template v-slot>
      <v-col cols="12">
        <ApolloQuery
          :query="require('../graphql/queries/getBlackListWords.gql')"
          :variables="{
            from,
            to,
          }"
        >
          <template v-slot="{ result: { error: wordsError, data: wordsData }, isLoading: wordsIsLoading }">
            <!-- Error -->
            <AppNotification
              v-if="wordsError"
              :message="wordsError.message"
            />

            <!-- Result -->
            <v-card
              v-else-if="wordsData || wordsIsLoading === 1"
              :loading="wordsIsLoading === 1"
              class="px-4 pb-3"
              flat
            >
              <template v-if="wordsData">
                <v-card-title class="py-7">
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      Запрещённые слова: {{ wordsData.getBlackListWords.wordsCount }}
                    </v-col>
                    <v-col class="text-right">
                      <ApolloMutation
                        :mutation="require('../graphql/mutations/addBlackListWord.gql')"
                        :update="updateCache"
                        :variables="{
                          word,
                        }"
                      >
                        <template v-slot="{ mutate, loading, error: createError }">
                          <v-dialog
                            v-model="dialog"
                            max-width="500px"
                            persistent
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                class="text-none"
                                color="primary"
                                depressed
                                height="36"
                                large
                                v-on="on"
                              >
                                Добавить
                              </v-btn>
                            </template>

                            <v-card :loading="loading">
                              <v-card-title>
                                <span class="text-h5">Добавить слово</span>
                              </v-card-title>

                              <v-card-text>
                                <v-text-field
                                  v-model="word"
                                  hide-details="auto"
                                  label="Слово"
                                />
                              </v-card-text>

                              <v-card-actions>
                                <v-spacer />

                                <v-btn
                                  color="primary"
                                  text
                                  @click="close"
                                >
                                  Отмена
                                </v-btn>
                                <v-btn
                                  color="primary"
                                  text
                                  @click="mutate()"
                                >
                                  Добавить
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                          <AppNotification
                            v-if="createError"
                            :icon-disabled="true"
                            :message="createError.message"
                          />
                        </template>
                      </ApolloMutation>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-list v-if="wordsData.getBlackListWords.words.length !== 0">
                  <template v-for="(item, i) of wordsData.getBlackListWords.words">
                    <v-divider :key="`${i}-divider`" />

                    <v-list-item
                      :key="`${i}-${item}`"
                      class="px-6"
                    >
                      <v-list-item-content>
                        <v-list-item-title>{{ item }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <ApolloMutation
                          :mutation="require('../graphql/mutations/removeBlackListWord.gql')"
                          :update="updateCache"
                          :variables="{
                            word: item,
                          }"
                        >
                          <template v-slot="{ mutate, loading, error: removeError }">
                            <v-btn
                              :loading="loading"
                              color="error"
                              icon
                              @click="mutate()"
                            >
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <AppNotification
                              v-if="removeError"
                              :icon-disabled="true"
                              :message="removeError.message"
                            />
                          </template>
                        </ApolloMutation>
                      </v-list-item-action>
                    </v-list-item>

                    <v-divider
                      v-if="wordsData.getBlackListWords.words.length === i + 1"
                      :key="`${i}-divider-2`"
                    />
                  </template>
                </v-list>

                <div
                  v-else
                  class="text-center grey--text text-body-2 py-4"
                >
                  Слова отсутствуют
                </div>

                <div class="text-center pt-3">
                  <v-pagination
                    v-model="page"
                    :length="Math.ceil(wordsData.getBlackListWords.wordsCount / itemsPerPage)"
                    :total-visible="9"
                  />

                  <v-select
                    v-model="itemsPerPage"
                    :items="[5, 10, 15]"
                    class="pa-4 pt-5"
                    label="Слов на странице"
                    @change="firstPage"
                  />
                </div>
              </template>
            </v-card>

            <!-- No result -->
            <AppNotification
              v-else
              message="Нет результата :("
            />
          </template>
        </ApolloQuery>
      </v-col>

      <v-col>
        <v-dialog
          v-model="dialogMoneyRain"
          max-width="500px"
          persistent
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              block
              class="text-none rounded-lg"
              color="primary"
              depressed
              height="48"
              x-large
              v-on="on"
            >
              Денежный дождь
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              <span class="text-h5">Запустить денежный дождь</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="amount"
                      hide-details="auto"
                      label="Сумма раздачи"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="percent"
                      hide-details="auto"
                      label="Процент участникам"
                      suffix="%"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-menu
                      v-model="menu"
                      :close-on-content-click="false"
                      min-width="290px"
                      offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date"
                          v-bind="attrs"
                          hide-details="auto"
                          label="Дата"
                          readonly
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="date"
                        no-title
                        scrollable
                        @input="menu = false"
                      />
                    </v-menu>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      v-model="time"
                      hide-details="auto"
                      label="Время"
                      type="time"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer />

              <v-btn
                color="primary"
                text
                @click="closeMoneyRain"
              >
                Отмена
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="closeMoneyRain"
              >
                Включить
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </template>
  </AppRow>
</template>

<script>
import AppRow from '@/components/AppRow.vue';
import AppNotification from '@/components/AppNotification.vue';
import getBlackListWords from '@/graphql/queries/getBlackListWords.gql';

export default {
  components: {
    AppRow,
    AppNotification,
  },
  data() {
    return {
      word: '',
      dialog: false,
      page: 1,
      itemsPerPage: 5,
      dialogMoneyRain: false,
      amount: '',
      percent: '',
      menu: false,
      date: '',
      time: '',
    };
  },
  computed: {
    from() {
      return this.itemsPerPage * (this.page - 1);
    },
    to() {
      return this.from + this.itemsPerPage;
    },
  },
  methods: {
    updateCache(store, { data }) {
      const query = {
        query: getBlackListWords,
        variables: {
          from: this.from,
          to: this.to,
        },
      };
      // Read the query from cache
      const queryData = store.readQuery(query);

      if (data.addBlackListWord) {
        // Mutate cache result
        queryData.getBlackListWords.words.push(data.addBlackListWord);
        queryData.getBlackListWords.wordsCount += 1;
        // Write back to the cache
        store.writeQuery({
          query: getBlackListWords,
          data: queryData,
        });

        this.close();
      } else {
        // Look for the deleted item
        const index = queryData.getBlackListWords.words.findIndex(item => item === data.removeBlackListWord);

        if (index !== -1) {
          // Mutate cache result
          queryData.getBlackListWords.words.splice(index, 1);
          queryData.getBlackListWords.wordsCount -= 1;
          // Write back to the cache
          store.writeQuery({
            query: getBlackListWords,
            data: queryData,
          });
        }
      }
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.word = '';
      });
    },
    firstPage() {
      this.page = 1;
    },
    closeMoneyRain() {
      this.dialogMoneyRain = false;
    },
  },
};
</script>
