var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppRow',{attrs:{"permission":"CHAT_ADMINISTRATION"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-col',{attrs:{"cols":"12"}},[_c('ApolloQuery',{attrs:{"query":require('../graphql/queries/getBlackListWords.gql'),"variables":{
          from: _vm.from,
          to: _vm.to,
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var ref_result = ref.result;
        var wordsError = ref_result.error;
        var wordsData = ref_result.data;
        var wordsIsLoading = ref.isLoading;
return [(wordsError)?_c('AppNotification',{attrs:{"message":wordsError.message}}):(wordsData || wordsIsLoading === 1)?_c('v-card',{staticClass:"px-4 pb-3",attrs:{"loading":wordsIsLoading === 1,"flat":""}},[(wordsData)?[_c('v-card-title',{staticClass:"py-7"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_vm._v(" Запрещённые слова: "+_vm._s(wordsData.getBlackListWords.wordsCount)+" ")]),_c('v-col',{staticClass:"text-right"},[_c('ApolloMutation',{attrs:{"mutation":require('../graphql/mutations/addBlackListWord.gql'),"update":_vm.updateCache,"variables":{
                        word: _vm.word,
                      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var mutate = ref.mutate;
                      var loading = ref.loading;
                      var createError = ref.error;
return [_c('v-dialog',{attrs:{"max-width":"500px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-none",attrs:{"color":"primary","depressed":"","height":"36","large":""}},'v-btn',attrs,false),on),[_vm._v(" Добавить ")])]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"loading":loading}},[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Добавить слово")])]),_c('v-card-text',[_c('v-text-field',{attrs:{"hide-details":"auto","label":"Слово"},model:{value:(_vm.word),callback:function ($$v) {_vm.word=$$v},expression:"word"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.close}},[_vm._v(" Отмена ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return mutate()}}},[_vm._v(" Добавить ")])],1)],1)],1),(createError)?_c('AppNotification',{attrs:{"icon-disabled":true,"message":createError.message}}):_vm._e()]}}],null,true)})],1)],1)],1),(wordsData.getBlackListWords.words.length !== 0)?_c('v-list',[_vm._l((wordsData.getBlackListWords.words),function(item,i){return [_c('v-divider',{key:(i + "-divider")}),_c('v-list-item',{key:(i + "-" + item),staticClass:"px-6"},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1),_c('v-list-item-action',[_c('ApolloMutation',{attrs:{"mutation":require('../graphql/mutations/removeBlackListWord.gql'),"update":_vm.updateCache,"variables":{
                          word: item,
                        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var mutate = ref.mutate;
                        var loading = ref.loading;
                        var removeError = ref.error;
return [_c('v-btn',{attrs:{"loading":loading,"color":"error","icon":""},on:{"click":function($event){return mutate()}}},[_c('v-icon',[_vm._v("mdi-close")])],1),(removeError)?_c('AppNotification',{attrs:{"icon-disabled":true,"message":removeError.message}}):_vm._e()]}}],null,true)})],1)],1),(wordsData.getBlackListWords.words.length === i + 1)?_c('v-divider',{key:(i + "-divider-2")}):_vm._e()]})],2):_c('div',{staticClass:"text-center grey--text text-body-2 py-4"},[_vm._v(" Слова отсутствуют ")]),_c('div',{staticClass:"text-center pt-3"},[_c('v-pagination',{attrs:{"length":Math.ceil(wordsData.getBlackListWords.wordsCount / _vm.itemsPerPage),"total-visible":9},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}),_c('v-select',{staticClass:"pa-4 pt-5",attrs:{"items":[5, 10, 15],"label":"Слов на странице"},on:{"change":_vm.firstPage},model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}})],1)]:_vm._e()],2):_c('AppNotification',{attrs:{"message":"Нет результата :("}})]}}])})],1),_c('v-col',[_c('v-dialog',{attrs:{"max-width":"500px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-none rounded-lg",attrs:{"block":"","color":"primary","depressed":"","height":"48","x-large":""}},'v-btn',attrs,false),on),[_vm._v(" Денежный дождь ")])]}}]),model:{value:(_vm.dialogMoneyRain),callback:function ($$v) {_vm.dialogMoneyRain=$$v},expression:"dialogMoneyRain"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Запустить денежный дождь")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"hide-details":"auto","label":"Сумма раздачи"},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"hide-details":"auto","label":"Процент участникам","suffix":"%"},model:{value:(_vm.percent),callback:function ($$v) {_vm.percent=$$v},expression:"percent"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"min-width":"290px","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"hide-details":"auto","label":"Дата","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"hide-details":"auto","label":"Время","type":"time"},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.closeMoneyRain}},[_vm._v(" Отмена ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.closeMoneyRain}},[_vm._v(" Включить ")])],1)],1)],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }